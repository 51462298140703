/* ---=== Contents ===--- //
/*
 * 1.) Settings
 * #colors
 * #fonts
 *
 * 2.) Base
 * #html-body
 *
 * 3.) Objects
 * #wrappers
 * #buttons
 * #icons
 *
 * 4.) Components
 * #header-banner
 * #header-search
 * #site-menu
 * #site-footer
 * #product-filters
 * education
 * #home-page
 * #home-articles
 * #home-catalogs
 * #tabs
 *
 *
 */

/* --- #colors --- */
$tb-primary: #efb0c8;
$tb-highlight: #c5a781;
$tb-highlight-dark: #99745d; 
$tb-title: #797676;

/* --- #fonts --- */
@import "./styles/fonts";

/* ---=== Base ===--- */

/* --- #html-body --- */
$font-all: "Montserrat", sans-serif;

$section-titles: "Belleza", sans-serif;

body {
  font-family: $font-all;
  font-weight: 400;
}

img {
    max-width: 100%;
    height: auto;
}

/* ---=== Objects ===--- */

/* --- #wrappers --- */

.main-content {
    max-width: none;
    position: relative;
    padding-bottom: 30px;
}

.page--default .main-content {
    padding-bottom: 0;
}

.main-content__inner > div {
    max-width: 1000px;
    margin: 0 auto;
}

.has--pencil .pencil,
.has--pencil .pencil-multi,
.pencil-banner {
    max-width: none;
}

.page--default,
.page--explore-deals-default {
    .main-content__inner > div {
        max-width: none;
    }
}

.home-wrap > div {
    max-width: 1260px;
    margin: 0 auto;
}

.home-wrap .edu-banner,
.home-wrap .main-banner-wrap {
    max-width: none;
}

// Videos
.video-placeholder:before{
  padding-bottom: 53.25%;
}

/* --- Social Links --- */
.social__link {
  display: inline;
}

/* --- #buttons --- */

.button {
  color: #fff;
  background-color: #f6bbce;
}

.RadGrid_Silk .rgEditForm .button {
  color: #fff;
}

.button:hover {
  background: #e89bb4;
}

.button--line {
  color: #7e7e7e;
  background: #fff;
}

.button--line:hover {
  color: #7e7e7e;
  background: #eee;
}

.button--quickview {
  background: #f6bbce;
}

.button--quickview:hover {
  background: #e89bb4;
}

.button--text {
  color: #005ca0;
  background: none;
  border: none;
}

.button--text:hover {
  color: #005ca0;
  background: none;
  text-decoration: underline;
}

.button--pending {
  &::after {
    background-color: #f6bbce;
  }
}

/* --- #icons --- */

.i--twitter,
.i--facebook,
.i--linkedin,
.i--pinterest,
.i--youtube,
.i--wordpress,
.i--feed,
.i--instagram,
.i--bangstyle,
.i--email {
    background-color: $tb-primary;
}

.i--twitter-hover {
  background-color: #3dc7f4;
}

.i--facebook-hover {
  background-color: #3b5998;
}

.i--linkedin-hover {
  background-color: #00659b;
}

.i--pinterest-hover {
  background-color: #dc3e47;
}

.i--youtube-hover {
  background-color: #e40202;
}

.i--wordpress-hover {
  background-color: #247ca4;
}

.i--feed-hover {
  background-color: #e08e00;
}

.i--instagram-hover {
  background-color: #773eb1;
}

.i--bangstyle-hover {
  background-color: #9497ca;
}

.i--email {
  background-color: #333;
}

/* ---=== Components ===--- */

/* --- #breadcrumb --- */

.breadcrumb__link[href="/blog"] {
  display: none;
}

/* --- #home-page --- */

.home-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 0 45px;
}

.main-banner-wrap {
  margin: 0 auto 45px;
}

.bar, .sub-bar {
    margin: 0 0 0.5em;
    line-height: 1;
    color: $tb-title;
    font-family: $section-titles;
    font-weight: 200;
    text-align: center;
    text-transform: uppercase;
    background: none;
}

.bar {
    font-size: 32px;
}

.sub-bar {
    font-size: 24px;
}

.fs-text-container::after {
  content: "SHOP NOW";
  font-weight: 400;
  font-size: 16px;
  margin-top: 20px;
  color: #fff;
  border: 2px solid #fff;
  padding: 12px 20px;
}

/*.quote {
  padding-left: 0px;
}*/

/* --- #home-articles --- */
.home-wrap .home-articles {
    text-align: center;
    margin-top: 50px;

    .home-article__link {
        display: none;
    }
}

.home-articles .header--home {
    margin: 0 0 0.5em;
    font-size: 32px;
    line-height: 1;
    color: $tb-title;
    font-family: $section-titles;
    font-weight: 200;
    text-align: center;
    text-transform: uppercase;
    background: none;
}

@media only screen and (min-width: 820px) {
    .bar, .home-articles .header--home {        
        font-size: 40px;
    }

    .sub-bar {
        font-size: 32px;
    }
}    

.home-articles__item {
    margin-top: 10px;
    padding: 0 7px;
}

.home-articles__more {
    display: inline-block;
    margin-bottom: 30px;
    padding: 5px 20px;
    color: #f6bbce;
    font-weight: 800;
    font-size: 1.35em;
    text-align: center;
    text-transform: uppercase;
    background: #4b4b4b;
}

.home-articles__more span {
    display: inline;
    background: none;
    font-weight: 500;
}

.home-articles__more span::after {
    display: none;
}

.home-articles__inner {
    .home-articles__more {
        color: #fff;
        background: #f7aeca;
        text-decoration: none;
    }
}

.fader {
    display: none;
}

/* --- #home-catalogs --- */

.catalogs {
    display: none;
}

.catalogs {
    max-width: 1000px;
    margin: 45px auto;
    border-top: none;
}

.catalogs__text {
    width: 100%;
}

.catalog {
    border-bottom-color: #424242;
}

.catalog__link {
    color: #fff;
}

.catalog__text {
    padding: 8px 24px;
    text-transform: uppercase;
    background: #f6bbce;
}

.catalog__image {
    margin-right: -3px;
}

.catalog__text::after {
    display: none;
}

.catalog:last-child {
    border-bottom-color: #424242;
}

// Social
.instagram-widget__container {    
    margin: 40px auto;
}

/* --- #tabs --- */

.tabs-nav a {
    background-color: #f9f9f9;
}

.tabs-nav .tabs-nav--active {
    color: #fff;
    background: #f6bbce;
    border-color: #f6bbce;
}

.GridModal_Silk {
    display: none;
}

/* --- #home-sliders --- */
.bx-wrapper .bx-next,
.bx-wrapper .bx-next:hover,
.bx-wrapper .bx-prev,
.bx-wrapper .bx-prev:hover {
    background-position: 0 0;
}

.bx-wrapper .bx-prev {
    background-image: url("/content/themes/trubeauty/redesign/images/universalarrowsleft.svg");
}

.bx-wrapper .bx-next {
    background-image: url("/content/themes/trubeauty/redesign/images/universalarrowsright.svg");
}


.bx-wrapper .bx-pager.bx-default-pager a {
    background: #d4d4d4;
}

.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:hover {
    background: #f6bbcf;
}

.bx-controls-direction,
.bx-pager {
    display: block;
}

/* ---------------------------
#home-page-banners
--------------------------- */

.bx-wrapper .bx-controls-direction a {
    width: 48px;
    height: 48px;
    margin-top: -32px;
}

.bx-wrapper .bx-controls-direction {
    display: none;
}

@media only screen and (min-width: 520px) {
    .bx-wrapper .bx-controls-direction {
        display: block;
    }

    .secondary-banner-wrap .bx-controls-direction {
        display: none;
    }
}

.main-banner-wrap .bx-wrapper .bx-prev {    
    left: 5px;
}

.main-banner-wrap .bx-wrapper .bx-next {
    right: 5px;
}
/* ---------------------------
#product sliders
--------------------------- */
.slider--featured {
    margin-top: 30px;

    .bx-viewport .slidewrap a .slider-caption {
        font-weight: 500;
        font-size: 1em;
    }

    .bx-viewport .slidewrap a:after {
        left: 0px;
    }
}

.slider--products {
    .bx-pager {
        bottom: -10px;
    }

    .bx-has-controls-direction .bx-prev {
        left: 0px;
    }

    .bx-has-controls-direction .bx-next {
        right: 0px;
    }
}

/*.education-list__list td {
    border-bottom: 1px solid #aaa;
}

.education-list__list:first-child td {
    border-top: 1px solid #aaa;
}

.education-list__list--southampton {
    background-color: #f7aec8;
}

.education-list__list--rockville {
    background-color: #d2eaec;
}

.education-list__list--richmond {
    background-color: #b9badd;
}

.education-list__list--cockeysville {
    background-color: #b4d7b9;
}*/

/* Social */
.fs-wrapper div.fs-text-container {
    background-color: #c98ea4de !important;
}

.insta-link__container {
    .button {
        color: #c6ae8f !important;
        border-radius: 0px;
        border: 2px solid #c6ae8f;
        background-color: #fff;
        padding: 10px 15px;
        font-size: 16px;
        font-weight: 500;
    }
}

a.button.insta-link:hover {
    text-decoration: underline;
}

.fs-wrapper div.fs-text-container * {
    color: #ffffff;
    font-size: 50px;
}

@media only screen and (min-width: 800px) {
  .mp-checkout__summary {
    top: 250px;
  }
}

@import "styles/site-nav";
@import "styles/login";
@import "styles/site-footer";
@import "styles/in-salon-education";
@import "styles/header";
@import "styles/salon-Interactive";
@import "styles/gridModal";
@import "styles/home-body";
@import "styles/dashboard";
@import "styles/products";
@import "styles/slideranimation";
@import "styles/samples-toggle";
@import 'styles/explore-deals';
@import 'styles/education/education';
@import "styles/landing";