﻿.mission {
  .mission-container {
    text-align: center;
    margin: 90px 0;

    .text-title {
      font-family: $section-titles;
      font-size: 3.5em;
      color: #bca487;
    }

    .p-text {
      font-size: 21px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 1px;
    }

    .p-link {
      margin-top: 30px;

      a {
        color: #fff;
        background-color: #f7aeca;
        padding: 10px 15px;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}

/* secondary banners below mission */
.standard-banners--secondary {
    .standard-banner .standard-banner__button, 
    .standard-banner .standard-banner__title {
        display: none;
    }
}

@media only screen and (max-width: 680px) {
    .standard-banners--secondary .standard-banner {
        margin-top: 10px;
        margin-bottom: 10px;        
    }
}

.edu-banner {
    margin: 90px auto;

    .img-position {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;
        -webkit-box-pack: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        background-image: url('/content/themes/trubeauty/images/home-saloninteractive-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .iif-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column;

            .iif-title {
                font-size: 3.5em;
                font-family: $section-titles;
                color: #fff;
                margin-bottom: 10px;
                text-align: center;
                margin-top: 90px;
            }

            .iif-text {
                font-size: 1.6em;
                font-weight: 300;
                line-height: 40px;
                color: #fff;
                text-align: center;
                padding-bottom: 20px;
                letter-spacing: 1px;

                span {
                    font-weight: 600;
                }

                .p-links {
                    color: #fff;
                }
            }

            .edu-btn {
                margin-bottom: 100px;
            }

            .a-btn {
                color: #b99e7b;
                background-color: #fff;
                padding: 15px 30px;
                font-size: 1.3em;
                font-weight: 500;
            }
        }
    }
}

/* Home Buckets below education banner */
@media only screen and (max-width: 680px) {
    .home-buckets > div:last-of-type {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 1366px) {
    .mission-container {
        .p-text {
            line-height: 30px;
            letter-spacing: 0px;
        }
    }
}

@media (min-width: 414px) and (max-width: 735px) {
    .mission-container {
        .text-title {
            font-size: 25px !important;
        }

        .p-text {
            font-size: 1em !important;
            line-height: 20px;
            letter-spacing: 0px;
        }
    }
}

@media (min-width: 576px) and (max-width: 766px) {
    .edu-banner {
        .img-position {
            .iif-container {
                .iif-title {
                    font-size: 3em;
                }

                .iif-text {
                    font-size: 1.5em;
                    line-height: 50px;
                }

                .edu-btn {
                    .a-btn {
                        font-size: 1.2em;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .mission {
        .mission-container {
            .text-title {
                font-size: 21px;
            }

            .p-text {
                font-size: 0.8em;
                line-height: 20px;
                letter-spacing: 0px;
            }
        }
    }

    .edu-banner {
        margin: 40px auto;

        .img-position {
            .iif-container {                
                .iif-title {
                    font-size: 2em;
                    color: #fff;
                    margin-bottom: 0px;
                    text-align: right;
                    margin-top: 60px;
                }

                .iif-text {
                    font-size: 0.9em;
                    line-height: 35px;
                    padding-bottom: 0px;
                    margin-top: 3px;
                    margin-bottom: 30px;
                }

                .a-btn {
                    padding: 15px 25px;
                    font-size: 1.1em;
                }

                .edu-btn {
                    margin-bottom: 70px;
                }
            }
        }
    }
}