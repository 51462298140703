﻿.edu-splash {
    font-size: 18px;

    img {
        width: 100%;
    }
}

.edu-splash-buckets {
    text-align: center;    
}

.edu-splash-bucket {    
    font-weight: 500;
    color: #000;
    text-align: center;

    img {
        width: 300px;
    }

    a {
        margin:25px 0px;
    }

    .button span {
        padding: 8px;
    }
}

@media only screen and (min-width: 680px) {
    .edu-splash-bucket {
        a {
            margin-bottom: 0px;
        }
    }
}

.edu-splash .splash-banner__secondary {
    margin-top: 50px;
}

.edu-splash-bucket__header {
    padding-top: 25px;
    font-size: 36px;
    font-family: $section-titles, sans-serif;
}

.edu-splash__header {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 15px;

    &.edu-splash__header-cat {
        margin-bottom: -5px;
    }

    h2 {        
        font-weight: 500;
        font-size: 1.6em;
    }
}

.edu-splash__category {
    text-align: center;
    font-size: larger;
    margin-top: 20px;

    a {
        color: #000;
        font-size: 16px;
        font-weight: 500;
    }

    img {
        width: 300px;
    }
}

.edu-splash__category-link {
    padding: 10px 0px 5px;
}