.site-footer {
    max-width: none;
    margin: 0;
    padding-top: 0;
    color: $tb-title;
    background: #fff;
}

.site-footer a {
    color: $tb-title;
    font-weight: 400;
    text-decoration: none;
}

.footer-inner {
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 15px;
}

.site-footer__header {
  border-bottom: none;
}

.followUs {
    text-align: right;

    h2 {
        font-size: 15px;
        text-transform: uppercase;
    }

    .social {
        float: right;
    }
}

.site-footer__copyright small {
    font-weight: 400;
}

@media only screen and (max-width: 576px) {
  .site-footer__header {
    padding: 0px;
  }

  .followUs {
    text-align: left;
  }

  .followUs .social {
    float: none;
  }
}
