/*.home-article .article--animation,*/
.slidewrap a {
  &:after {
    content: "SHOP NOW";
    display: inline-block;
    position: relative;
    top: 0;
    left: 50%;
    opacity: 0;
    background: #f7aeca !important;
    color: #fff;
    padding: 10px 21px;
    transition: top 0.5s, opacity 0.5s;
    /*font-family: basic-sans, sans-serif;*/
    font-weight: 400 !important;
    font-style: normal;
    text-decoration: none;
    font-size: 15px;
    transform: translateX(-50%);
    border: none !important;
  }
}

.slidewrap a {
  .slider-caption {
    opacity: 1;
    transition: opacity 0.5s;
  }

  &:hover {
    text-decoration: none;

    &:after {
      top: -13px;
      opacity: 1;
    }

    .slider-caption {
      opacity: 0 !important;
    }
  }
}

/*.home-article {
  a:after {
    content: none;
  }

  .home-article__link {
    position: relative;
  }

  .article--animation:after {
    content: "READ NOW";
    position: absolute;
    top: auto;
    left: 0;
    bottom: -13px;
    right: 0;
    padding: 10px 20px;
    transform: translateY(100%);
    transition: bottom 0.5s, opacity 0.5s;
  }

  &:hover {
    .article--animation:after {
      top: auto;
      bottom: 0;
      opacity: 1 !important;
      transition: bottom 0.5s, opacity 0.5s;
    }

    .home-article__text {
      opacity: 0 !important;
    }
  }

  .home-article__text {
    opacity: 1;
    transition: opacity 0.5s;
  }
}*/

.banner--slider {
  .slidewrap a:after {
    content: "SHOP NOW";
  }

  .slidewrap a:hover:after {
    top: -34px;
    opacity: 1;
  }
}
@media (max-width: 480px) {
  .banner--slider .slidewrap a:after,
  .slidewrap a:after {
    left: 100px;
  }

  .slidewrap a:hover:after {
    left: 100px;
    padding: 10px 20px;
  }

  .banner--slider .slidewrap a:hover:after {
    top: -23px;
  }

  /*.home-article:hover {
    .article--animation:after {
      left: 0;
    }
  }*/
}
@media (max-width: 380px) {
  .slidewrap a:after {
    padding: 10px;
    left: 28px;
    font-size: 14px;
  }

  .slidewrap a:hover:after {
    left: 28px;
    padding: 10px;
    font-size: 14px;
  }

  .banner--slider .slidewrap a:after {
    padding: 10px;
    left: 40px;
    font-size: 14px;
  }

  .banner--slider .slidewrap a:hover:after {
    top: -20px;
    font-size: 14px;
  }
}
