﻿$promo-background-color: #F9F2F2;

.explore-deals-wrap {
    h1.bar {
        color: #fff;
    }

    h2.bar,
    h2.bar span,
    .home-article__link {
        color: #707070;
    }

    .button {
        font-weight: 700;
    }

    .home-articles__list {
        margin: 0 -10px 20px;
    }
}

.promo-header {
    background-color: #f7aeca;
    color: #fff;

    .button {
        background-color: #fff;
        color: #f7aeca;
    }
}

.promo-resources__flex, 
.promo-banner__content-wrap {
    background-color: $promo-background-color;
}

.product-list__header-link,
.promo-resources a {
    color: $tb-highlight;
}
