/* ---=== Contents ===--- //
/*
 * 1.) Settings
 * #colors
 * #fonts
 *
 * 2.) Base
 * #html-body
 *
 * 3.) Objects
 * #wrappers
 * #buttons
 * #icons
 *
 * 4.) Components
 * #header-banner
 * #header-search
 * #site-menu
 * #site-footer
 * #product-filters
 * education
 * #home-page
 * #home-articles
 * #home-catalogs
 * #tabs
 *
 *
 */
/* --- #colors --- */
/* --- #fonts --- */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Belleza:wght@200;400;500;700;800&display=swap");
/* ---=== Base ===--- */
/* --- #html-body --- */
body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

/* ---=== Objects ===--- */
/* --- #wrappers --- */
.main-content {
  max-width: none;
  position: relative;
  padding-bottom: 30px;
}

.page--default .main-content {
  padding-bottom: 0;
}

.main-content__inner > div {
  max-width: 1000px;
  margin: 0 auto;
}

.has--pencil .pencil,
.has--pencil .pencil-multi,
.pencil-banner {
  max-width: none;
}

.page--default .main-content__inner > div,
.page--explore-deals-default .main-content__inner > div {
  max-width: none;
}

.home-wrap > div {
  max-width: 1260px;
  margin: 0 auto;
}

.home-wrap .edu-banner,
.home-wrap .main-banner-wrap {
  max-width: none;
}

.video-placeholder:before {
  padding-bottom: 53.25%;
}

/* --- Social Links --- */
.social__link {
  display: inline;
}

/* --- #buttons --- */
.button {
  color: #fff;
  background-color: #f6bbce;
}

.RadGrid_Silk .rgEditForm .button {
  color: #fff;
}

.button:hover {
  background: #e89bb4;
}

.button--line {
  color: #7e7e7e;
  background: #fff;
}

.button--line:hover {
  color: #7e7e7e;
  background: #eee;
}

.button--quickview {
  background: #f6bbce;
}

.button--quickview:hover {
  background: #e89bb4;
}

.button--text {
  color: #005ca0;
  background: none;
  border: none;
}

.button--text:hover {
  color: #005ca0;
  background: none;
  text-decoration: underline;
}

.button--pending::after {
  background-color: #f6bbce;
}

/* --- #icons --- */
.i--twitter,
.i--facebook,
.i--linkedin,
.i--pinterest,
.i--youtube,
.i--wordpress,
.i--feed,
.i--instagram,
.i--bangstyle,
.i--email {
  background-color: #efb0c8;
}

.i--twitter-hover {
  background-color: #3dc7f4;
}

.i--facebook-hover {
  background-color: #3b5998;
}

.i--linkedin-hover {
  background-color: #00659b;
}

.i--pinterest-hover {
  background-color: #dc3e47;
}

.i--youtube-hover {
  background-color: #e40202;
}

.i--wordpress-hover {
  background-color: #247ca4;
}

.i--feed-hover {
  background-color: #e08e00;
}

.i--instagram-hover {
  background-color: #773eb1;
}

.i--bangstyle-hover {
  background-color: #9497ca;
}

.i--email {
  background-color: #333;
}

/* ---=== Components ===--- */
/* --- #breadcrumb --- */
.breadcrumb__link[href="/blog"] {
  display: none;
}

/* --- #home-page --- */
.home-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 0 45px;
}

.main-banner-wrap {
  margin: 0 auto 45px;
}

.bar, .sub-bar {
  margin: 0 0 0.5em;
  line-height: 1;
  color: #797676;
  font-family: "Belleza", sans-serif;
  font-weight: 200;
  text-align: center;
  text-transform: uppercase;
  background: none;
}

.bar {
  font-size: 32px;
}

.sub-bar {
  font-size: 24px;
}

.fs-text-container::after {
  content: "SHOP NOW";
  font-weight: 400;
  font-size: 16px;
  margin-top: 20px;
  color: #fff;
  border: 2px solid #fff;
  padding: 12px 20px;
}

/*.quote {
  padding-left: 0px;
}*/
/* --- #home-articles --- */
.home-wrap .home-articles {
  text-align: center;
  margin-top: 50px;
}

.home-wrap .home-articles .home-article__link {
  display: none;
}

.home-articles .header--home {
  margin: 0 0 0.5em;
  font-size: 32px;
  line-height: 1;
  color: #797676;
  font-family: "Belleza", sans-serif;
  font-weight: 200;
  text-align: center;
  text-transform: uppercase;
  background: none;
}

@media only screen and (min-width: 820px) {
  .bar, .home-articles .header--home {
    font-size: 40px;
  }
  .sub-bar {
    font-size: 32px;
  }
}

.home-articles__item {
  margin-top: 10px;
  padding: 0 7px;
}

.home-articles__more {
  display: inline-block;
  margin-bottom: 30px;
  padding: 5px 20px;
  color: #f6bbce;
  font-weight: 800;
  font-size: 1.35em;
  text-align: center;
  text-transform: uppercase;
  background: #4b4b4b;
}

.home-articles__more span {
  display: inline;
  background: none;
  font-weight: 500;
}

.home-articles__more span::after {
  display: none;
}

.home-articles__inner .home-articles__more {
  color: #fff;
  background: #f7aeca;
  text-decoration: none;
}

.fader {
  display: none;
}

/* --- #home-catalogs --- */
.catalogs {
  display: none;
}

.catalogs {
  max-width: 1000px;
  margin: 45px auto;
  border-top: none;
}

.catalogs__text {
  width: 100%;
}

.catalog {
  border-bottom-color: #424242;
}

.catalog__link {
  color: #fff;
}

.catalog__text {
  padding: 8px 24px;
  text-transform: uppercase;
  background: #f6bbce;
}

.catalog__image {
  margin-right: -3px;
}

.catalog__text::after {
  display: none;
}

.catalog:last-child {
  border-bottom-color: #424242;
}

.instagram-widget__container {
  margin: 40px auto;
}

/* --- #tabs --- */
.tabs-nav a {
  background-color: #f9f9f9;
}

.tabs-nav .tabs-nav--active {
  color: #fff;
  background: #f6bbce;
  border-color: #f6bbce;
}

.GridModal_Silk {
  display: none;
}

/* --- #home-sliders --- */
.bx-wrapper .bx-next,
.bx-wrapper .bx-next:hover,
.bx-wrapper .bx-prev,
.bx-wrapper .bx-prev:hover {
  background-position: 0 0;
}

.bx-wrapper .bx-prev {
  background-image: url("/content/themes/trubeauty/redesign/images/universalarrowsleft.svg");
}

.bx-wrapper .bx-next {
  background-image: url("/content/themes/trubeauty/redesign/images/universalarrowsright.svg");
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background: #d4d4d4;
}

.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:hover {
  background: #f6bbcf;
}

.bx-controls-direction,
.bx-pager {
  display: block;
}

/* ---------------------------
#home-page-banners
--------------------------- */
.bx-wrapper .bx-controls-direction a {
  width: 48px;
  height: 48px;
  margin-top: -32px;
}

.bx-wrapper .bx-controls-direction {
  display: none;
}

@media only screen and (min-width: 520px) {
  .bx-wrapper .bx-controls-direction {
    display: block;
  }
  .secondary-banner-wrap .bx-controls-direction {
    display: none;
  }
}

.main-banner-wrap .bx-wrapper .bx-prev {
  left: 5px;
}

.main-banner-wrap .bx-wrapper .bx-next {
  right: 5px;
}

/* ---------------------------
#product sliders
--------------------------- */
.slider--featured {
  margin-top: 30px;
}

.slider--featured .bx-viewport .slidewrap a .slider-caption {
  font-weight: 500;
  font-size: 1em;
}

.slider--featured .bx-viewport .slidewrap a:after {
  left: 0px;
}

.slider--products .bx-pager {
  bottom: -10px;
}

.slider--products .bx-has-controls-direction .bx-prev {
  left: 0px;
}

.slider--products .bx-has-controls-direction .bx-next {
  right: 0px;
}

/*.education-list__list td {
    border-bottom: 1px solid #aaa;
}

.education-list__list:first-child td {
    border-top: 1px solid #aaa;
}

.education-list__list--southampton {
    background-color: #f7aec8;
}

.education-list__list--rockville {
    background-color: #d2eaec;
}

.education-list__list--richmond {
    background-color: #b9badd;
}

.education-list__list--cockeysville {
    background-color: #b4d7b9;
}*/
/* Social */
.fs-wrapper div.fs-text-container {
  background-color: #c98ea4de !important;
}

.insta-link__container .button {
  color: #c6ae8f !important;
  border-radius: 0px;
  border: 2px solid #c6ae8f;
  background-color: #fff;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 500;
}

a.button.insta-link:hover {
  text-decoration: underline;
}

.fs-wrapper div.fs-text-container * {
  color: #ffffff;
  font-size: 50px;
}

@media only screen and (min-width: 800px) {
  .mp-checkout__summary {
    top: 250px;
  }
}

.site-nav__item--sale .site-nav__top {
  color: #fff;
  background: #f7aec8;
}

.site-nav__top {
  letter-spacing: 1px;
}

.sub-nav a {
  font-weight: 400;
  line-height: 20px;
  color: #797676;
}

.sub-nav__inner ul {
  padding-left: 0px;
}

.sub-nav__inner ul li {
  list-style-type: none;
}

@media only screen and (min-width: 820px) {
  .site-nav {
    max-width: none;
    margin: 0;
    background: #fff;
    box-shadow: 0px 2px #00000009;
  }
  .site-nav__list {
    max-width: 1200px;
    margin: 0 auto;
  }
  .site-nav__top--inactive {
    cursor: pointer;
    pointer-events: all;
  }
  .site-nav__top {
    position: relative;
    margin: 0;
    padding: 15px 25px;
    font-size: 13px;
    color: #797676;
    font-weight: 500;
    border-radius: 0;
  }
  .sub-nav {
    margin-top: 0;
    padding: 0;
    border-radius: 0;
    border: 1px solid #ddd;
    border-top: 2px solid #f6bbce;
  }
  .sub-nav__inner {
    padding: 10px 10px 15px;
  }
  .sub-nav__col {
    width: 175px;
  }
  .sub-nav__highlight {
    color: #99745d;
  }
  .site-nav__item:hover .site-nav__top,
  .site-nav__top:hover {
    color: #fff;
    background-color: #f6bbce;
  }
  .site-nav__item--holiday {
    background-color: #f6bbce;
  }
  .site-nav__item--holiday .site-nav__top {
    color: #fff;
  }
  .site-nav__item--holiday:hover {
    background-color: #f4f4f4;
  }
  .site-nav__item--holiday:hover .site-nav__top {
    background-color: #f4f4f4;
    color: #555;
  }
  .site-nav__top:hover + .sub-nav {
    display: block;
  }
}

.menu-wrap .search {
  display: none;
}

.nav-toggles,
.nav-toggle {
  background-color: #fff;
  justify-self: center;
}

.burger {
  width: 30px;
  height: 23px;
}

.burger__middle {
  background: #000;
  top: 10px;
}

.burger::before,
.burger::after {
  background: #000;
}

@media only screen and (max-width: 620px) {
  .menu-wrap {
    border: 0px;
    display: grid;
    grid-template-columns: 1fr 4fr;
    padding: 0 0 10px;
  }
  .menu-wrap .search {
    display: flex;
    max-width: unset;
  }
}

.login-section__header {
  font-size: 24px;
}

.site-footer {
  max-width: none;
  margin: 0;
  padding-top: 0;
  color: #797676;
  background: #fff;
}

.site-footer a {
  color: #797676;
  font-weight: 400;
  text-decoration: none;
}

.footer-inner {
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 15px;
}

.site-footer__header {
  border-bottom: none;
}

.followUs {
  text-align: right;
}

.followUs h2 {
  font-size: 15px;
  text-transform: uppercase;
}

.followUs .social {
  float: right;
}

.site-footer__copyright small {
  font-weight: 400;
}

@media only screen and (max-width: 576px) {
  .site-footer__header {
    padding: 0px;
  }
  .followUs {
    text-align: left;
  }
  .followUs .social {
    float: none;
  }
}

.in-salon {
  font-weight: 400;
}

.in-salon .in-salon-header__image {
  width: 100%;
  max-width: none;
}

@media only screen and (min-width: 1000px) {
  .in-salon-header {
    margin-right: calc(-100vw / 2 + 1000px / 2);
    margin-left: calc(-100vw / 2 + 1000px / 2);
  }
}

.in-salon-classes {
  margin-top: 30px;
}

.in-salon-classes__brand {
  position: relative;
  margin-bottom: 30px;
  padding: 30px 0;
  border-bottom: 8px solid #f4bcce;
}

.in-salon-classes__brand::before, .in-salon-classes__brand::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border: solid transparent;
  pointer-events: none;
}

.in-salon-classes__brand::before {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #f4bcce;
  border-width: 20px;
  margin-left: -20px;
}

@media only screen and (min-width: 560px) {
  .in-salon-classes {
    margin-top: 60px;
  }
  .in-salon-classes__brand {
    border-bottom-width: 15px;
  }
  .in-salon-classes__brand::before {
    border-width: 35px;
    margin-left: -35px;
  }
}

.in-salon-logo {
  max-width: 75%;
  margin: 0 auto;
}

.in-salon-logo__graphic {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.in-salon-class {
  margin: 20px 0;
  font-size: 14px;
}

.in-salon-class .in-salon-class__title {
  font-size: 18px;
  font-weight: 700;
}

.in-salon {
  margin: 0;
  padding: 0;
  font-size: 14px;
  text-transform: uppercase;
}

.in-salon .in-salon-class__description {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.in-salon-class__price {
  display: inline-block;
  margin-right: 5px;
  color: #f4bcce;
  font-weight: 800;
}

.in-salon-class__details {
  margin-top: 10px;
}

.in-salon-book {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 45px;
  font-size: 12px;
}

.in-salon-book__icon {
  margin-right: 15px;
}

.in-salon .in-salon-book__title {
  margin: 0;
  padding: 0 0 5px;
  font-size: 16px;
  font-weight: 800;
}

.in-salon-book__text p {
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 560px) {
  .in-salon-class {
    font-size: 18px;
  }
  .in-salon-book {
    font-size: 16px;
  }
  .in-salon .in-salon-book__title {
    font-size: 24px;
  }
}

.in-salon .in-salon-brands__header {
  margin: 30px 0;
  padding: 0;
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
}

.in-salon-brand {
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #ccc;
  text-align: center;
}

@media only screen and (min-width: 460px) {
  .in-salon-brands__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .in-salon-brand {
    width: calc(50% - 15px);
  }
}

@media only screen and (min-width: 620px) {
  .in-salon-brand {
    width: calc(100% / 3 - 15px);
  }
}

.in-salon-brand__photo, .in-salon-brand__logo {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.in-salon-brand__link {
  margin: 15px 0;
  padding: 8px 24px;
  font-weight: bold;
  border-radius: 0;
}

.in-salon-brand__link--all {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 36px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.2;
  background: #f2bdce;
}

.in-salon-brand__link--all span {
  width: 100%;
  padding: 15px;
  text-align: center;
}

.in-salon-divider {
  position: relative;
  margin: 30px 0;
  border-bottom: 15px solid #f4bcce;
}

.in-salon-divider::before, .in-salon-divider::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border: solid transparent;
  pointer-events: none;
}

.in-salon-divider::before {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #f4bcce;
  border-width: 30px;
  margin-left: -30px;
}

.in-salon-how {
  margin: 45px 0 30px;
}

.in-salon .in-salon-how__header {
  margin: 30px 0;
  padding: 0;
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
}

@media only screen and (min-width: 620px) {
  .in-salon-how__sections {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }
}

.in-salon-how__section {
  padding: 0 30px 15px;
  text-align: center;
}

.in-salon-how .in-salon__icon {
  margin: 0 auto 15px;
}

.in-salon .in-salon-how__note {
  margin: 0;
  font-size: 16px;
  font-style: italic;
  text-align: center;
}

.in-salon .in-salon-all__header, .in-salon .in-salon-all__header:first-of-type {
  margin: 45px 0 30px;
  padding: 0;
  font-size: 42px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
}

.in-salon-classes--all .in-salon-class {
  margin-top: 0;
  margin-bottom: 60px;
}

.in-salon-classes--all .in-salon-logo__graphic {
  max-width: 200px;
  margin: 60px 0 30px;
}

.top-header {
  background-color: #efb0c8;
  font-size: 13px;
}

.top-header__inner {
  max-width: 1600px;
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 0 auto;
  align-items: center;
}

.header-top__note {
  display: none;
}

.header-top__note--mobile {
  grid-row: 1;
  grid-column: 1;
  padding: 15px;
  text-align: left;
  align-self: center;
  background-color: #efb0c8;
}

.header-top__assistance {
  grid-column: 1;
  padding: 0;
  margin-left: 8px;
  text-transform: capitalize;
}

.header-top__assistance--mobile {
  display: none;
}

.site-header .google-translate {
  max-width: 1600px;
  padding-left: 5px;
}

.utility-bar {
  grid-row: 2;
  grid-column: 1 / span 2;
  background-color: #efb0c8;
  align-self: center;
}

.utility-bar a {
  color: #fff;
}

.utility-bar__inner {
  grid-template-columns: 1fr auto auto;
  max-width: 1600px;
  color: #fff;
  align-items: center;
}

.login-links {
  grid-column: 2;
  text-transform: uppercase;
}

.account-header {
  grid-column: 2;
  background-color: #efb0c8;
}

.account-header__icon {
  display: block;
  justify-self: right;
}

.account-header__toggle {
  display: block;
  justify-self: left;
}

.header-cart__value {
  color: #efb0c8;
}

.account-header__nav {
  background-color: #efb0c8;
}

.account-header__nav a {
  color: #fff;
}

.header-cart {
  grid-column: 3;
}

.login-links,
.header-cart,
.account-header__account-name,
.header-top__countdown {
  margin-right: 8px;
}

.header-search {
  grid-column: 3;
  width: 100%;
  justify-self: center;
}

.header-banner .header-search {
  padding: 15px;
}

.search__options {
  display: none;
}

.search {
  max-width: 250px;
  margin: 0 auto;
}

.search__text {
  max-width: unset;
}

.search__field {
  padding: 10px;
}

.search__submit {
  flex: 0 0 20px;
}

.button--search {
  width: 20px;
  height: 20px;
  background: url(/content/themes/trubeauty/redesign/images/search-img.svg) no-repeat 0 0;
  background-size: 20px 20px;
}

.header-top__countdown {
  grid-row: 1;
  grid-column: 2;
  width: 100%;
  padding-top: 5px;
  padding-right: 10px;
  color: #fff;
}

.header-top--added {
  background-color: #338326;
}

.site-logo {
  padding: 0px;
  grid-column: 2;
  text-align: center;
  line-height: 0.5;
}

.site-logo__img {
  width: 275px;
}

.site-logo--mobile {
  display: none;
}

.header-banner {
  display: grid;
  align-items: center;
  max-width: 1600px;
  font-size: 15px;
  padding: 5px 10px;
  transition: padding 0.3s;
}

.header-banner__assistance {
  justify-self: right;
}

.title-wrap,
.is--logged-in .title-wrap {
  padding-top: 0;
}

@media only screen and (min-width: 680.02px) {
  .header-banner {
    grid-template-columns: 1fr 300px 1fr;
  }
}

@media only screen and (min-width: 820px) {
  .account-header__nav {
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.16);
  }
}

@media only screen and (min-width: 820px) and (min-height: 600px) {
  .fixed-header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #fff;
  }
}

@media only screen and (max-width: 620px) {
  .top-header {
    background-color: #fff;
  }
  .header-top__note--mobile {
    width: 100%;
    grid-column: 1 / span 2;
    padding: 10px 5px;
    text-align: center;
  }
  .header-top__assistance {
    display: none;
    grid-column: unset;
  }
  .header-top__assistance--mobile {
    grid-row: 2;
    grid-column: 1 / span 2;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #efb0c8;
  }
  .header-top__assistance--mobile a {
    color: #fff;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .header-top__countdown {
    grid-row: 5;
    grid-column: 1 / span 2;
    text-align: center;
    padding: 5px 0 10px;
    margin: 0 auto;
    color: #000;
  }
  .site-logo--mobile {
    grid-row: 6;
    padding: 20px 10px 10px;
    width: 300px;
    margin: 0 auto;
    display: block;
    grid-column: 1 / span 2;
    background-color: #fff;
  }
  .site-logo--mobile .site-logo__img {
    margin: 0 auto;
  }
  .utility-bar {
    grid-row: 7;
    grid-column: 1 / span 2;
    background-color: #fff;
    padding: 0px;
  }
  .utility-bar a {
    color: #000;
  }
  .utility-bar__inner {
    grid-template-columns: 80% 20%;
    justify-items: center;
    color: #000;
  }
  .account-header {
    width: 100%;
    grid-column: 1;
    color: #000;
    background-color: #fff;
  }
  .header-cart {
    grid-column: 2;
    justify-self: end;
  }
  .account-header__account-greeting {
    display: none;
  }
  .account-header__header-i-guess {
    display: grid;
    grid-template-columns: auto auto 75%;
    align-items: center;
  }
  .account-header__icon {
    grid-column: 1;
    grid-row: 1;
  }
  .account-header__icon .icon-person .icon-circle {
    stroke: #000;
    fill: #fff;
    stroke-width: 1;
  }
  .account-header__icon .icon-person .icon-body,
  .account-header__icon .icon-person .icon-head {
    fill: #000;
  }
  /* --looks redundant, is not --*/
  .account-header__nav a {
    color: #fff;
  }
  .header-cart__value {
    color: #fff;
  }
  .account-header__toggle {
    grid-column: 2;
    grid-row: 1;
    padding: 5px 10px 0 5px;
  }
  .account-header__toggle .header-cart__value {
    color: #fff;
  }
  .account-header__toggle svg,
  .header-cart__icon svg {
    fill: #000;
  }
  .account-header__title {
    grid-column: 3;
    text-align: left;
  }
  .search {
    display: none;
  }
  .header-banner {
    display: none;
  }
  .login-links {
    grid-column: 1;
    justify-self: start;
    padding-left: 20px;
  }
  .header-search {
    padding-right: 10px;
    width: 100%;
    grid-column: 2;
  }
}

.post_title {
  display: none;
}

.main {
  margin-top: 60px;
}

.main .row .img-text .h2-intro {
  margin-top: 30px;
  font-size: 32px;
  font-weight: 700;
}

.main .row .img-text .h2-intro span {
  font-weight: 300;
}

.main .row .video-container {
  margin-top: 100px;
}

.main .row .video-container .video-text {
  text-align: center;
}

.online-shopping .img-container {
  position: relative;
}

.online-shopping .img-container .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.online-shopping .img-container .btn a {
  font-weight: 700;
  font-size: 26px;
  text-transform: uppercase;
  padding: 25px;
  color: #393939;
  background-color: #fff;
  text-decoration: none;
}

@media only screen and (max-width: 767.98px) {
  .main .row .video-container {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 991.98px) {
  .main {
    margin-top: 0px;
  }
  .main .row .img-text .h2-intro {
    font-size: 24px;
    line-height: 28px;
  }
  .main .row .img-text .p-storeInfo {
    font-size: 18px;
  }
  .main .row .video-container .video-text {
    margin-bottom: 40px;
  }
  .online-shopping .img-container .btn a {
    font-size: 10px;
    padding: 15px;
  }
}

.recent-orders__popup {
  margin-top: 50px !important;
}

.GridModal_ {
  display: none !important;
}

.mission .mission-container {
  text-align: center;
  margin: 90px 0;
}

.mission .mission-container .text-title {
  font-family: "Belleza", sans-serif;
  font-size: 3.5em;
  color: #bca487;
}

.mission .mission-container .p-text {
  font-size: 21px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 1px;
}

.mission .mission-container .p-link {
  margin-top: 30px;
}

.mission .mission-container .p-link a {
  color: #fff;
  background-color: #f7aeca;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: 500;
}

/* secondary banners below mission */
.standard-banners--secondary .standard-banner .standard-banner__button,
.standard-banners--secondary .standard-banner .standard-banner__title {
  display: none;
}

@media only screen and (max-width: 680px) {
  .standard-banners--secondary .standard-banner {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.edu-banner {
  margin: 90px auto;
}

.edu-banner .img-position {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  -webkit-box-pack: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  background-image: url("/content/themes/trubeauty/images/home-saloninteractive-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.edu-banner .img-position .iif-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.edu-banner .img-position .iif-container .iif-title {
  font-size: 3.5em;
  font-family: "Belleza", sans-serif;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 90px;
}

.edu-banner .img-position .iif-container .iif-text {
  font-size: 1.6em;
  font-weight: 300;
  line-height: 40px;
  color: #fff;
  text-align: center;
  padding-bottom: 20px;
  letter-spacing: 1px;
}

.edu-banner .img-position .iif-container .iif-text span {
  font-weight: 600;
}

.edu-banner .img-position .iif-container .iif-text .p-links {
  color: #fff;
}

.edu-banner .img-position .iif-container .edu-btn {
  margin-bottom: 100px;
}

.edu-banner .img-position .iif-container .a-btn {
  color: #b99e7b;
  background-color: #fff;
  padding: 15px 30px;
  font-size: 1.3em;
  font-weight: 500;
}

/* Home Buckets below education banner */
@media only screen and (max-width: 680px) {
  .home-buckets > div:last-of-type {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1366px) {
  .mission-container .p-text {
    line-height: 30px;
    letter-spacing: 0px;
  }
}

@media (min-width: 414px) and (max-width: 735px) {
  .mission-container .text-title {
    font-size: 25px !important;
  }
  .mission-container .p-text {
    font-size: 1em !important;
    line-height: 20px;
    letter-spacing: 0px;
  }
}

@media (min-width: 576px) and (max-width: 766px) {
  .edu-banner .img-position .iif-container .iif-title {
    font-size: 3em;
  }
  .edu-banner .img-position .iif-container .iif-text {
    font-size: 1.5em;
    line-height: 50px;
  }
  .edu-banner .img-position .iif-container .edu-btn .a-btn {
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 575px) {
  .mission .mission-container .text-title {
    font-size: 21px;
  }
  .mission .mission-container .p-text {
    font-size: 0.8em;
    line-height: 20px;
    letter-spacing: 0px;
  }
  .edu-banner {
    margin: 40px auto;
  }
  .edu-banner .img-position .iif-container .iif-title {
    font-size: 2em;
    color: #fff;
    margin-bottom: 0px;
    text-align: right;
    margin-top: 60px;
  }
  .edu-banner .img-position .iif-container .iif-text {
    font-size: 0.9em;
    line-height: 35px;
    padding-bottom: 0px;
    margin-top: 3px;
    margin-bottom: 30px;
  }
  .edu-banner .img-position .iif-container .a-btn {
    padding: 15px 25px;
    font-size: 1.1em;
  }
  .edu-banner .img-position .iif-container .edu-btn {
    margin-bottom: 70px;
  }
}

.myaccount-nav__link {
  background-color: #f6bbce;
}

.myaccount-nav__link--selected, .myaccount-nav__link:hover {
  background-color: #e89bb4;
}

.dashboard-buckets img {
  max-width: 100%;
  height: auto;
}

.filters__header {
  color: #333;
  background-color: #fce7f1;
}

.filters__header a {
  color: inherit;
}

.deal-sku__link {
  color: #c5a781;
}

.bxgy__header {
  background-color: #efb0c8;
}

/*.home-article .article--animation,*/
.slidewrap a:after {
  content: "SHOP NOW";
  display: inline-block;
  position: relative;
  top: 0;
  left: 50%;
  opacity: 0;
  background: #f7aeca !important;
  color: #fff;
  padding: 10px 21px;
  transition: top 0.5s, opacity 0.5s;
  /*font-family: basic-sans, sans-serif;*/
  font-weight: 400 !important;
  font-style: normal;
  text-decoration: none;
  font-size: 15px;
  transform: translateX(-50%);
  border: none !important;
}

.slidewrap a .slider-caption {
  opacity: 1;
  transition: opacity 0.5s;
}

.slidewrap a:hover {
  text-decoration: none;
}

.slidewrap a:hover:after {
  top: -13px;
  opacity: 1;
}

.slidewrap a:hover .slider-caption {
  opacity: 0 !important;
}

/*.home-article {
  a:after {
    content: none;
  }

  .home-article__link {
    position: relative;
  }

  .article--animation:after {
    content: "READ NOW";
    position: absolute;
    top: auto;
    left: 0;
    bottom: -13px;
    right: 0;
    padding: 10px 20px;
    transform: translateY(100%);
    transition: bottom 0.5s, opacity 0.5s;
  }

  &:hover {
    .article--animation:after {
      top: auto;
      bottom: 0;
      opacity: 1 !important;
      transition: bottom 0.5s, opacity 0.5s;
    }

    .home-article__text {
      opacity: 0 !important;
    }
  }

  .home-article__text {
    opacity: 1;
    transition: opacity 0.5s;
  }
}*/
.banner--slider .slidewrap a:after {
  content: "SHOP NOW";
}

.banner--slider .slidewrap a:hover:after {
  top: -34px;
  opacity: 1;
}

@media (max-width: 480px) {
  .banner--slider .slidewrap a:after,
  .slidewrap a:after {
    left: 100px;
  }
  .slidewrap a:hover:after {
    left: 100px;
    padding: 10px 20px;
  }
  .banner--slider .slidewrap a:hover:after {
    top: -23px;
  }
  /*.home-article:hover {
    .article--animation:after {
      left: 0;
    }
  }*/
}

@media (max-width: 380px) {
  .slidewrap a:after {
    padding: 10px;
    left: 28px;
    font-size: 14px;
  }
  .slidewrap a:hover:after {
    left: 28px;
    padding: 10px;
    font-size: 14px;
  }
  .banner--slider .slidewrap a:after {
    padding: 10px;
    left: 40px;
    font-size: 14px;
  }
  .banner--slider .slidewrap a:hover:after {
    top: -20px;
    font-size: 14px;
  }
}

.samples__toggle {
  background: none;
  background-color: #c5a781;
  color: #fff;
}

.samples__toggle--svg-container {
  background-color: #947854;
}

.explore-deals-wrap h1.bar {
  color: #fff;
}

.explore-deals-wrap h2.bar,
.explore-deals-wrap h2.bar span,
.explore-deals-wrap .home-article__link {
  color: #707070;
}

.explore-deals-wrap .button {
  font-weight: 700;
}

.explore-deals-wrap .home-articles__list {
  margin: 0 -10px 20px;
}

.promo-header {
  background-color: #f7aeca;
  color: #fff;
}

.promo-header .button {
  background-color: #fff;
  color: #f7aeca;
}

.promo-resources__flex,
.promo-banner__content-wrap {
  background-color: #F9F2F2;
}

.product-list__header-link,
.promo-resources a {
  color: #c5a781;
}

.edu-splash {
  font-size: 18px;
}

.edu-splash img {
  width: 100%;
}

.edu-splash-buckets {
  text-align: center;
}

.edu-splash-bucket {
  font-weight: 500;
  color: #000;
  text-align: center;
}

.edu-splash-bucket img {
  width: 300px;
}

.edu-splash-bucket a {
  margin: 25px 0px;
}

.edu-splash-bucket .button span {
  padding: 8px;
}

@media only screen and (min-width: 680px) {
  .edu-splash-bucket a {
    margin-bottom: 0px;
  }
}

.edu-splash .splash-banner__secondary {
  margin-top: 50px;
}

.edu-splash-bucket__header {
  padding-top: 25px;
  font-size: 36px;
  font-family: "Belleza", sans-serif, sans-serif;
}

.edu-splash__header {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 15px;
}

.edu-splash__header.edu-splash__header-cat {
  margin-bottom: -5px;
}

.edu-splash__header h2 {
  font-weight: 500;
  font-size: 1.6em;
}

.edu-splash__category {
  text-align: center;
  font-size: larger;
  margin-top: 20px;
}

.edu-splash__category a {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.edu-splash__category img {
  width: 300px;
}

.edu-splash__category-link {
  padding: 10px 0px 5px;
}

.landing {
  padding-top: 0px !important;
}

.landing-about_container {
  max-width: 1000px;
}

.landing-about_container p {
  line-height: 22px;
  font-size: 14px;
  color: #4C4C4C;
}

.landing-about_container h2 {
  font-weight: 400;
  font-size: 40px;
  color: #797676;
}

.landing-about_container span {
  font-weight: 700;
}

.landing-about_container .desktop-banner {
  display: block;
}

.landing-about_container .mobile-banner {
  display: none;
}

.about-bxs {
  display: flex;
}

.about-bx {
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-about__text {
  padding: 0 50px;
}

.landing-about__text .button {
  margin: 0;
  color: #fff;
}

.bx-mobile .landing-about__text {
  padding-left: 0px;
  padding-right: 50px;
}

.landing-about__mission {
  background-color: #FCE7F1;
  text-align: center;
  padding: 50px 10px;
}

.landing-titles {
  font-family: "Belleza", sans-serif;
}

.landing-title__small {
  color: #c5a781;
  font-family: "Montserrat", sans-serif;
}

.landing-about__section {
  margin: 60px 0;
}

.about__button {
  padding: 15px;
  margin-top: 30px;
}

.about__button a {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}

.landing-text {
  margin-top: 30px;
}

p.landing-text__big {
  font-size: 28px;
  line-height: 30px;
  font-family: "Belleza", sans-serif;
  color: #797676;
}

.landing-approach__title {
  text-align: center;
  background-color: #99745d;
  padding: 30px 0 10px;
  margin-bottom: 60px;
}

.landing-approach__title h2 {
  color: #fff;
}

.landing-approach {
  background-color: #F9F2F2;
  padding: 0 0 50px 0;
}

@media only screen and (min-width: 800px) {
  .bx-full {
    padding: 0px;
  }
  .about-bx__section {
    padding: 0 20px;
  }
  .landing-about__mission {
    padding: 50px 80px;
  }
}

@media only screen and (max-width: 819px) {
  .about-bxs {
    display: block;
  }
  .bx-mobile .landing-about__text {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 680px) {
  .landing-about_container .desktop-banner {
    display: none;
  }
  .landing-about_container .mobile-banner {
    display: block;
    margin: 0 auto;
  }
  .landing-about__text {
    padding: 25px 0;
  }
  .landing-about_container h2 {
    font-size: 32px;
  }
  .landing__outer .landing__body {
    padding: 0;
  }
  .landing__outer .g-8.md-12 {
    display: none;
  }
  .landing-about__mission {
    padding: 50px 20px;
  }
  .about-bx {
    padding: 0 20px;
  }
  .mobile-grid {
    display: grid;
  }
  .mobile-grid__bx {
    grid-row: 1 / 1;
  }
}
