﻿.filters__header {
    color: #333;
    background-color: #fce7f1;
}

.filters__header a {
    color: inherit;
}

.deal-sku__link {
    color: $tb-highlight;
}

.bxgy__header {
    background-color: $tb-primary;
}