﻿//--------------------- Begin new mobile header ----------------//
.top-header {
    background-color: $tb-primary;
    font-size: 13px;
}

.top-header__inner {
    max-width: 1600px;
    display: grid;
    grid-template-columns: 1fr auto;
    margin: 0 auto;
    align-items: center;
}

.header-top__note {
    display: none;
}

.header-top__note--mobile {
    grid-row: 1;
    grid-column: 1;
    padding: 15px;
    text-align: left;
    align-self: center;
    background-color: $tb-primary;
}

.header-top__assistance {    
    grid-column: 1;
    padding: 0;
    margin-left: 8px;
    text-transform: capitalize;
}

.header-top__assistance--mobile {
    display: none;
}

.site-header .google-translate {
    max-width: 1600px;    
    padding-left: 5px;
}

.utility-bar {
    grid-row: 2;
    grid-column: 1 / span 2;
    background-color: $tb-primary;
    align-self: center;

    a {
        color: #fff;
    }
}

.utility-bar__inner {
    grid-template-columns: 1fr auto auto;
    max-width: 1600px;
    color: #fff;
    align-items: center;
}

.login-links {
    grid-column: 2;
    text-transform: uppercase;
}

.account-header {
    grid-column: 2;
    background-color: $tb-primary;
}

.account-header__icon {
    display: block;
    justify-self: right;
}

.account-header__toggle {
    display: block;
    justify-self: left;
}

.header-cart__value {
    color: $tb-primary;
}

.account-header__nav {
    background-color: $tb-primary;

    a {
        color: #fff;
    }
}

.header-cart {
    grid-column: 3;
}

.login-links,
.header-cart,
.account-header__account-name,
.header-top__countdown {
    margin-right: 8px;
}

.header-search {
    grid-column: 3;
    width: 100%;
    justify-self: center;
}

.header-banner .header-search {
    padding: 15px;
}

.search__options {
    display: none;
}

.search {
    max-width: 250px;
    margin: 0 auto;
}

.search__text {
    max-width: unset;
}

.search__field {
    padding: 10px;
}

.search__submit {
    flex: 0 0 20px;
}

.button--search {
    width: 20px;
    height: 20px;
    background: url(/content/themes/trubeauty/redesign/images/search-img.svg) no-repeat 0 0;
    background-size: 20px 20px;
}

.header-top__countdown {
    grid-row: 1;
    grid-column: 2;
    width: 100%;
    padding-top: 5px;
    padding-right: 10px;
    color: #fff;
}

.header-top--added {
    background-color: #338326;
}

.site-logo {
    padding: 0px;
    grid-column: 2;
    text-align: center;
    line-height: 0.5;
}

.site-logo__img {
    width: 275px;
}

.site-logo--mobile {
    display: none;
}

.header-banner {
    display: grid;
    align-items: center;
    max-width: 1600px;
    font-size: 15px;
    padding: 5px 10px;
    transition: padding 0.3s;
}

.header-banner__assistance {
    justify-self: right;
}

.title-wrap,
.is--logged-in .title-wrap {
    padding-top: 0;
}

@media only screen and (min-width: 680.02px) {
    .header-banner {
        grid-template-columns: 1fr 300px 1fr;
    }
}

@media only screen and (min-width: 820px) {
    .account-header__nav {
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.16);
    }
}

@media only screen and (min-width: 820px) and (min-height: 600px) {
    .fixed-header {
        position: sticky;
        top: 0;
        z-index: 1000;
        background-color: #fff;
    }
}

@media only screen and (max-width: 620px) {
    .top-header {
        background-color: #fff;
    }

    .header-top__note--mobile {
        width: 100%;
        grid-column: 1 / span 2;
        padding: 10px 5px;
        text-align: center;
    }

    .header-top__assistance {
        display: none;
        grid-column: unset;
    }

    .header-top__assistance--mobile {
        grid-row: 2;
        grid-column: 1 / span 2;
        display: flex;
        align-items: center;
        padding: 10px;
        background-color: $tb-primary;

        a {
            color: #fff;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
        }
    }

    .header-top__countdown {
        grid-row: 5;
        grid-column: 1 / span 2;
        text-align: center;
        padding: 5px 0 10px;
        margin: 0 auto;
        color: #000;
    }

    .site-logo--mobile {
        grid-row: 6;
        padding: 20px 10px 10px;
        width: 300px;
        margin: 0 auto;
        display: block;
        grid-column: 1 / span 2;
        background-color: #fff;

        .site-logo__img {
            margin: 0 auto;
        }
    }    

    .utility-bar {
        grid-row: 7;
        grid-column: 1 / span 2;
        background-color: #fff;
        padding: 0px;

        a {
            color: #000;
        }
    }

    .utility-bar__inner {
        grid-template-columns: 80% 20%;
        justify-items: center;
        color: #000;
    }

    .account-header {
        width: 100%;
        grid-column: 1;
        color: #000;
        background-color: #fff;
    }

    .header-cart {
        grid-column: 2;
        justify-self: end;
    }

    .account-header__account-greeting {
        display: none;
    }

    .account-header__header-i-guess {
        display: grid;
        grid-template-columns: auto auto 75%;
        align-items: center;
    }

    .account-header__icon {
        grid-column: 1;
        grid-row: 1;

        .icon-person .icon-circle {
            stroke: #000;
            fill: #fff;
            stroke-width: 1;
        }

        .icon-person .icon-body,
        .icon-person .icon-head {
            fill: #000;
        }
    }

    /* --looks redundant, is not --*/
    .account-header__nav a {
        color: #fff;
    }

    .header-cart__value {
        color: #fff;
    }

    .account-header__toggle {
        grid-column: 2;
        grid-row: 1;
        padding: 5px 10px 0 5px;        

        .header-cart__value {
            color: #fff;
        }
    }

    .account-header__toggle svg,
    .header-cart__icon svg {
        fill: #000;
    }

    .account-header__title {
        grid-column: 3;
        text-align: left;
    }

    .search {
        display: none;
    }

    .header-banner {
        display: none;
    }

    .login-links {
        grid-column: 1;
        justify-self: start;
        padding-left: 20px;
    }

    .header-search {
        padding-right: 10px;
        width: 100%;
        grid-column: 2;
    }
}
//---------------------- End new mobile header -----------------//