.landing {
    padding-top: 0px !important;
}

// ABOUT
.landing-about_container{
    max-width: 1000px;

      p {
        line-height: 22px;
        font-size: 14px;
        color: #4C4C4C;
      }

      h2 {
        font-weight: 400;
        font-size: 40px;
        color: #797676;
      }
  
      span{
        font-weight: 700;
      }
  
      .desktop-banner{
        display: block;
      }
  
      .mobile-banner{
        display: none;
      }
}  
  
.about-bxs {
    display: flex;
}
    
.about-bx {
    display: flex;
    justify-content: center;
    align-items: center;
}
    
.landing-about__text {
    padding: 0 50px;

    .button {
        margin: 0;
        color: #fff;
    }
}
    
.bx-mobile {
    .landing-about__text {
        padding-left: 0px;
        padding-right: 50px;
    }
}  
  
.landing-about__mission{
    background-color: #FCE7F1;
    text-align: center;
    padding: 50px 10px;
}

.landing-titles {
    font-family: $section-titles;
}
  
.landing-title__small {
    color: $tb-highlight;
    font-family: $font-all
}
  
.landing-about__section {
    margin: 60px 0;
}
  
.about__button {
    padding: 15px;
    margin-top: 30px;

    a {
        font-size: 16px;
        color: #fff;
        font-weight: 600;
    }
}
  
.landing-text {
    margin-top: 30px;
}
  
p.landing-text__big {
    font-size: 28px;
    line-height: 30px;
    font-family: $section-titles;
    color: $tb-title;
}

.landing-approach__title {
    text-align: center;
    background-color: $tb-highlight-dark;
    padding: 30px 0 10px;
    margin-bottom: 60px;

    h2 {
        color: #fff;
    }
}
  
.landing-approach {
    background-color: #F9F2F2;
    padding: 0 0 50px 0;
}
  
@media only screen and (min-width: 800px) {   
    .bx-full{
      padding: 0px;
    }
  
    .about-bx__section{
        padding: 0 20px;
    }
  
    .landing-about__mission{
        padding: 50px 80px;
    }
}
    
@media only screen and (max-width: 819px) {
    .about-bxs {
        display: block;
    }
    
    .bx-mobile {
        .landing-about__text {
            padding-right: 0px;
        }
    }
}
    
@media only screen and (max-width: 680px) {
    .landing-about_container{
        .desktop-banner{
            display: none;
        }
    
        .mobile-banner{
            display: block;
            margin: 0 auto;
        }
    } 
    
    .landing-about__text {
        padding: 25px 0;
    }
  
    .landing-about_container {
        h2 {
            font-size: 32px;
        }
    }    
  
    .landing__outer{
        .landing__body {
            padding: 0 ;
        }

        .g-8.md-12 {
            display: none;
        }
    }

    .landing-about__mission {
        padding: 50px 20px;
    }

    .about-bx {
        padding: 0 20px;
    }

    .mobile-grid {
        display: grid;
    }

    .mobile-grid__bx{
        grid-row: 1 / 1;
    }
}
  