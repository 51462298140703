.site-nav__item--sale .site-nav__top {
  color: #fff;
  background: #f7aec8;
}

.site-nav__top {
    letter-spacing: 1px;    
}

.sub-nav a {
    font-weight: 400;
    line-height: 20px;
    color: $tb-title;
}

.sub-nav__inner {
    ul {
        padding-left: 0px;

        li {
            list-style-type: none;
        }
    }
}

@media only screen and (min-width: 820px) {
  .site-nav {
    max-width: none;
    margin: 0;
    background: #fff;
    box-shadow: 0px 2px #00000009;
  }

  .site-nav__list {
    max-width: 1200px;
    margin: 0 auto;
  }

  .site-nav__top--inactive {
    cursor: pointer;
    pointer-events: all;
  }

    .site-nav__top {
        position: relative;
        margin: 0;
        padding: 15px 25px;
        font-size: 13px;
        color: $tb-title;
        font-weight: 500;
        border-radius: 0;
    }

  .sub-nav {
    margin-top: 0;
    padding: 0;
    border-radius: 0;
    border: 1px solid #ddd;
    border-top: 2px solid #f6bbce;  
  }
    
  .sub-nav__inner {
    padding: 10px 10px 15px;
  }

    .sub-nav__col {
        width: 175px;
    }

    .sub-nav__highlight {
        color: $tb-highlight-dark;
    }

  .site-nav__item:hover .site-nav__top,
  .site-nav__top:hover {
    color: #fff;
    background-color: #f6bbce;
  }

  .site-nav__item--holiday {
    background-color: #f6bbce;

    .site-nav__top {
      color: #fff;
    }

    &:hover {
      background-color: #f4f4f4;

      .site-nav__top {
        background-color: #f4f4f4;
        color: #555;
      }
    }
  }

  .site-nav__top:hover + .sub-nav {
    display: block;
  }  
}

//------- global mobile changes ------//

.menu-wrap .search {
    display: none;
}

.nav-toggles,
.nav-toggle {
    background-color: #fff;
    justify-self: center;
}

.burger {
    width: 30px;
    height: 23px;
}

.burger__middle {
    background: #000;
    top: 10px;
}

.burger::before,
.burger::after {
    background: #000;
}

@media only screen and (max-width: 620px) {
    .menu-wrap {
        border: 0px;
        display: grid;
        grid-template-columns: 1fr 4fr;
        padding: 0 0 10px;

        .search {
            display: flex;
            max-width: unset;
        }
    }
}