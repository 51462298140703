.in-salon {
	font-weight: 400;
}

.in-salon .in-salon-header__image {
	width: 100%;
	max-width: none;
}

@media only screen and (min-width: 1000px) {
	.in-salon-header {
		margin-right: calc(-100vw / 2 + 1000px / 2);
		margin-left: calc(-100vw / 2 + 1000px / 2);
	}
}

.in-salon-classes {
	margin-top: 30px;
}

.in-salon-classes__brand {
	position: relative;
	margin-bottom: 30px;
	padding: 30px 0;
	border-bottom: 8px solid #f4bcce;
}

.in-salon-classes__brand::before, .in-salon-classes__brand::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	width: 0;
	height: 0;
	border: solid transparent;
	pointer-events: none;
}

.in-salon-classes__brand::before {
	border-color: rgba(255, 255, 255, 0);
	border-top-color: #f4bcce;
	border-width: 20px;
	margin-left: -20px;
}

@media only screen and (min-width: 560px) {
	.in-salon-classes {
		margin-top: 60px;
	}

	.in-salon-classes__brand {
		border-bottom-width: 15px;
	}

	.in-salon-classes__brand::before {
		border-width: 35px;
		margin-left: -35px;
	}
}

.in-salon-logo {
	max-width: 75%;
	margin: 0 auto;
}

.in-salon-logo__graphic {
	display: block;
	max-width: 100%;
	height: auto;
	margin: 0 auto;
}

.in-salon-class {
	margin: 20px 0;
	font-size: 14px;

	.in-salon-class__title {
		font-size: 18px;
		font-weight: 700;
	}
}

.in-salon {
	margin: 0;
	padding: 0;
	font-size: 14px;
	text-transform: uppercase;
}

.in-salon .in-salon-class__description {
	margin: 0;
	padding: 0;
	font-size: 14px;
}

.in-salon-class__price {
	display: inline-block;
	margin-right: 5px;
	color: #f4bcce;
	font-weight: 800;
}

.in-salon-class__details {
	margin-top: 10px;
}

.in-salon-book {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	margin-top: 45px;
	font-size: 12px;
}

.in-salon-book__icon {
	margin-right: 15px;
}

.in-salon .in-salon-book__title {
	margin: 0;
	padding: 0 0 5px;
	font-size: 16px;
	font-weight: 800;
}

.in-salon-book__text p {
	margin: 0;
	padding: 0;
}

@media only screen and (min-width: 560px) {
	.in-salon-class {
		font-size: 18px;
	}

	.in-salon-book {
		font-size: 16px;
	}

	.in-salon .in-salon-book__title {
		font-size: 24px;
	}
}

.in-salon .in-salon-brands__header {
	margin: 30px 0;
	padding: 0;
	font-size: 24px;
	font-weight: 800;
	text-align: center;
	text-transform: uppercase;
}

.in-salon-brand {
	width: 100%;
	margin-bottom: 30px;
	border: 1px solid #ccc;
	text-align: center;
}

@media only screen and (min-width: 460px) {
	.in-salon-brands__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.in-salon-brand {
		width: calc(50% - 15px);
	}
}

@media only screen and (min-width: 620px) {
	.in-salon-brand {
		width: calc(100% / 3 - 15px);
	}
}

.in-salon-brand__photo, .in-salon-brand__logo {
	display: block;
	max-width: 100%;
	height: auto;
	margin: 0 auto;
}

.in-salon-brand__link {
	margin: 15px 0;
	padding: 8px 24px;
	font-weight: bold;
	border-radius: 0;
}

.in-salon-brand__link--all {
	display: flex;
	align-items: center;
	color: #fff;
	font-size: 36px;
	font-weight: 800;
	text-transform: uppercase;
	line-height: 1.2;
	background: #f2bdce;

	span {
		width: 100%;
		padding: 15px;
		text-align: center;
	}
}

.in-salon-divider {
	position: relative;
	margin: 30px 0;
	border-bottom: 15px solid #f4bcce;

	&::before, &::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		width: 0;
		height: 0;
		border: solid transparent;
		pointer-events: none;
	}

	&::before {
		border-color: rgba(255, 255, 255, 0);
		border-top-color: #f4bcce;
		border-width: 30px;
		margin-left: -30px;
	}
}

.in-salon-how {
	margin: 45px 0 30px;
}

.in-salon .in-salon-how__header {
	margin: 30px 0;
	padding: 0;
	font-size: 24px;
	font-weight: 800;
	text-align: center;
	text-transform: uppercase;
}

@media only screen and (min-width: 620px) {
	.in-salon-how__sections {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
	}
}

.in-salon-how__section {
	padding: 0 30px 15px;
	text-align: center;
}

.in-salon-how {
	.in-salon__icon {
		margin: 0 auto 15px;
	}
}

.in-salon .in-salon-how__note {
	margin: 0;
	font-size: 16px;
	font-style: italic;
	text-align: center;
}

.in-salon .in-salon-all__header, .in-salon .in-salon-all__header:first-of-type {
	margin: 45px 0 30px;
	padding: 0;
	font-size: 42px;
	font-weight: 800;
	text-align: center;
	text-transform: uppercase;
}

.in-salon-classes--all {
	.in-salon-class {
		margin-top: 0;
		margin-bottom: 60px;
	}

	.in-salon-logo__graphic {
		max-width: 200px;
		margin: 60px 0 30px;
	}
}