﻿.post_title {
  display: none;
}

.main {
  margin-top: 60px;
  .row {
    .img-text {
      .h2-intro {
        margin-top: 30px;
        font-size: 32px;
        font-weight: 700;
        span {
          font-weight: 300;
        }
      }      
    }
    .video-container {
      margin-top: 100px;
      .video-text {
        text-align: center;
      }
    }
  }
}
.online-shopping {
  .img-container {
    position: relative;
    .btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      a {
        font-weight: 700;
        font-size: 26px;
        text-transform: uppercase;
        padding: 25px;
        color: #393939;
        background-color: #fff;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .main {
    .row {
      .video-container {
        margin-top: 0px;
      }
    }
  }
}

@media only screen and (max-width: 991.98px) {
  .main {
    margin-top: 0px;
    .row {
      .img-text {
        .h2-intro {
          font-size: 24px;
          line-height: 28px;
        }
        .p-storeInfo {
          font-size: 18px;
        }
      }
      .video-container {
        .video-text {
          margin-bottom: 40px;
        }
      }
    }
  }
  .online-shopping {
    .img-container {
      .btn {
        a {
          font-size: 10px;
          padding: 15px;
        }
      }
    }
  }
}
