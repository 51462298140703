﻿.myaccount-nav__link {
    background-color: #f6bbce;

    &--selected,
    &:hover {
        background-color: #e89bb4;
    }
}

.dashboard-buckets img {
    max-width: 100%;
    height: auto;
}